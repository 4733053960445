var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",staticStyle:{"margin-top":"0"}},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.inicio_formated),callback:function ($$v) {_vm.inicio_formated=$$v},expression:"inicio_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de termino","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.termino_formated),callback:function ($$v) {_vm.termino_formated=$$v},expression:"termino_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.termino),callback:function ($$v) {_vm.termino=$$v},expression:"termino"}})],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","color":"blue darken-1"},on:{"click":_vm.loadPartes}},[_c('i',{staticClass:"material-icons"},[_vm._v("cached")]),_vm._v(" Generar")]),_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","color":"green"},on:{"click":_vm.download}},[_c('i',{staticClass:"material-icons"},[_vm._v("text_snippet")]),_vm._v(" Excel")])],1)],1),(_vm.spinner)?_c('spinner'):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.partesListHeaders,"items":_vm.partesListBody,"no-data-text":"No hay partes","search":_vm.search,"fixed-header":"","height":_vm.table_height,"header-props":{ sortIcon: 'arrow_upward' },"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.zarpe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.zarpe).format("DD-MM-YYYY HH:mm")))])]}},{key:"item.recalada",fn:function(ref){
var item = ref.item;
return [(item.recalada)?_c('span',[_vm._v(_vm._s(_vm.moment(item.recalada).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.atraco_fondeo",fn:function(ref){
var item = ref.item;
return [(item.atraco_fondeo)?_c('span',[_vm._v(_vm._s(_vm.moment(item.atraco_fondeo).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.inicio_faena",fn:function(ref){
var item = ref.item;
return [(item.inicio_faena)?_c('span',[_vm._v(_vm._s(_vm.moment(item.inicio_faena).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.termino_faena",fn:function(ref){
var item = ref.item;
return [(item.termino_faena)?_c('span',[_vm._v(_vm._s(_vm.moment(item.termino_faena).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.termino_cam",fn:function(ref){
var item = ref.item;
return [(item.termino_cam)?_c('span',[_vm._v(_vm._s(_vm.moment(item.termino_cam).format("DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.tiempo_faena",fn:function(ref){
var item = ref.item;
return [(item.tiempo_faena)?_c('span',[_vm._v(_vm._s(item.tiempo_faena.substring(0, 5)))]):_vm._e()]}},{key:"item.tiempo_no_operativo",fn:function(ref){
var item = ref.item;
return [(item.tiempo_no_operativo)?_c('span',[_vm._v(_vm._s(item.tiempo_no_operativo.substring(0, 5)))]):_vm._e()]}},{key:"item.horas",fn:function(ref){
var item = ref.item;
return [(item.horas)?_c('span',[_vm._v(_vm._s(item.horas.substring(0, 5)))]):_vm._e()]}},{key:"item.millas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.millas, 0))+" ")]}},{key:"item.nudos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.nudos, 0))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"primary--text"},[_c('th',{staticClass:"title text-left",attrs:{"colspan":"13"}},[_vm._v("Totales")]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm.sumaTiempoFaena))]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm.totalNoOperativo))]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm.formatNumber(_vm.sumaMillas, 0)))]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm.totalHorasNoNavegadas))]),_c('th',{staticClass:"title text-right"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.promedioNudos, 0))+" ")]),_c('th')])])],2)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header card-header-icon card-header-rose"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("notes")])]),_c('h3',{staticClass:"card-title"},[_vm._v("PARTES DE VIAJE")])])}]

export { render, staticRenderFns }